@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');

body {
  font-family: 'Noto Sans JP', sans-serif;
  margin: 0;
}

svg {
  height: 48px;
  width: 48px;
  margin: 10px;
  cursor: pointer;
}

svg:hover {
  margin: 0 10px 20px 10px;
}

a {
  text-decoration: none;
  color: #1c9cea;
}

#powered {
  color: #fff;
  position: absolute;
  left: 10px;
  top: 10px;
}

