.app {
  text-align: center;
}

.app-section {
  background-color: #1a1c22;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1c9cea;
}

form {
  width: 90vw;
}

.input-url {
  background: #c6c8cc;
  border: none;
  width: 50%;
  height: 26px;
  border-radius: 10px 0 0 10px;
  border: solid 1px #1c9cea;
}

.input-url:focus {
  outline: none;
  height: 24px;
  border: solid 2px #1c9cea;
}

.button-submit {
  background: #1c9cea;
  color: #1a1c22;
  border: none;
  height: 30px;
  width: 20%;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  font-weight: bold;
}

.button-submit:hover {
  background-color: #0a3550;
}